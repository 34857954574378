<script setup lang="ts">
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import groupFields from '~/forms/newsletter/form/groupFields'

const { sizeImage, source } = defineProps<{
  sizeImage: { height: string; width: string }
  source: 'pop_in' | 'block_lp'
}>()
defineSlots<{ 'after-text'(): any }>()

const { $lcRepositories } = useNuxtApp()
const localePath = useLocalePath()
const { t } = useI18n({ useScope: 'local' })
const { btnPrimary } = useButtons()
const { trackEvent } = useTracking()
const { user } = useUser()

btnPrimary.text = computed(() => t('subscribe'))
if (btnPrimary.attrs) {
  btnPrimary.attrs.class = 'base-form-button'
  btnPrimary.attrs['data-cy'] = 'submit-newsletter'
}

const groupFieldsForm = reactive(groupFields())
const initialData = reactive<{ email?: string }>({
  email: user.value.email,
})
const configForm = reactive({
  fields: {
    email: {
      attr: {
        disabled: Boolean(user.value.email),
        placeholder: computed(() => t('placeholderEmail')),
      },
    },
  },
})
const success = ref(false)

const submitNewsletter = (formData: { email: string }) =>
  $lcRepositories.newsletterEmails.subscribeNewsletter(formData)
const mutation = (data: { email: string }) => ({
  request: submitNewsletter,
  input: {
    email: data.email,
  },
})
const onSubmit = () => ({
  success: () => {
    initialData.email = ''
    success.value = true
    trackEvent({
      event: 'newsletter_subscription',
      label: source,
      post_alert: false,
    })
  },
  error: () => {
    success.value = false
  },
})
</script>

<template>
  <div class="flex flex-col lg:flex-row">
    <div
      :class="[
        'flex-none',
        {
          'lg:w-[536px]': source === 'block_lp',
          'lg:w-[407px]': source === 'pop_in',
        },
      ]"
    >
      <BaseNuxtImg
        :height="sizeImage.height"
        :width="sizeImage.width"
        alt="image newsletter"
        src="production/assets/images/newsletter/popin-nl.jpg"
      />
    </div>

    <div class="w-full p-6 lg:flex lg:flex-col lg:justify-center lg:px-10">
      <div v-if="success" class="text-center">
        <div class="font-monospace mb-2 text-3xl font-bold leading-8">
          {{ t('subscribed.title') }}
        </div>

        <p class="mb-6 text-md">
          {{ t('subscribed.text') }}
        </p>

        <slot name="after-text" />
      </div>
      <div v-else class="text-center">
        <div class="font-monospace mb-2 text-3xl font-bold leading-8">
          {{ t('title') }}
        </div>

        <p
          :class="[
            'mb-6 text-md',
            {
              'xl:whitespace-break-spaces': source === 'block_lp',
            },
          ]"
        >
          {{ t('text') }}
        </p>

        <BaseForm
          :active-modal-errors="false"
          :active-modal-save-or-quit="false"
          :button-primary="btnPrimary"
          :config-form="configForm"
          :group-fields="groupFieldsForm"
          :initial-data="initialData"
          :mutation="mutation"
          :on-submit="onSubmit"
          class="newsletter-form"
          container-button-class="mt-3 flex-wrap container-button-class"
          id-form="newsletter-form"
          wrapper-button-class="justify-center"
        />

        <I18nT
          class="mb-0 mt-3 text-xs text-gray-400"
          keypath="terms[0]"
          tag="p"
        >
          <template #link>
            <a
              :href="localePath('legal-privacyPolicy')"
              target="_blank"
              class="text-gray-400 underline"
            >
              {{ t('terms[1]') }}
            </a>
          </template>
        </I18nT>
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "placeholderEmail": "Enter your email address",
    "subscribed": {
      "title": "Your registration is now validated!",
      "text": "You'll soon be receiving travel ideas and much more."
    },
    "subscribe": "Subscribe",
    "title": "Subscribe to our newsletter",
    "text": "Receive personalized content on the most beautiful homes in the world,\n the most iconic destinations and breathtaking experiences.",
    "terms": [
      "By subscribing, you declare that you have read and accepted our {link}",
      "Privacy Policy."
    ]
  },
  "fr": {
    "placeholderEmail": "Renseignez votre adresse e-mail",
    "subscribed": {
      "title": "Votre inscription est validée !",
      "text": "Vous recevrez prochainement des idées de voyages et bien plus encore."
    },
    "subscribe": "Je m'abonne",
    "title": "Abonnez-vous à notre newsletter",
    "text": "Recevez des contenus personnalisés sur les plus belles maisons du monde,\n les destinations les plus iconiques et nos expériences époustouflantes.",
    "terms": [
      "En vous abonnant, vous déclarez avoir pris connaissance et accepté notre {link}",
      "Politique de confidentialité."
    ]
  }
}
</i18n>
